import React, { useContext, useState, useEffect } from "react";
import { ProgramContext } from "../../contexts/Program";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { exportProgramToFile, copyProgramToClipboard } from "../file/yamlOperations";

import db from "../../data/database";

// antd components
import { message, Card, Button, Modal, List, Skeleton, Tooltip, ConfigProvider } from "antd";
import {
	ExclamationCircleOutlined,
} from "@ant-design/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faSave, faCopy } from "@fortawesome/free-regular-svg-icons"
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"

// Components
import { VIEW } from "../forms/FormManager";
import ProgramModal from "../Modals/ProgramModal";
import renderNoData from "../components/NoData"

const { confirm } = Modal;

const File = () => {
	const program = useContext(ProgramContext);
	const { loadProgram, clearProgram } = program;
	
	const history = useHistory();

	const [fileman, setFileman] = useState([]);
	const [deleted, setDeleted] = useState(false); // reload database when a program is deleted
	const [creatorVisible, setCreatorVisible] = useState(false); // show the create program modal
	
	const doCreateProgram = () => {
		clearProgram();
		setCreatorVisible(true)
	}

	const doEditClick = async (id) => {
		await loadProgram(id);
		history.push("/review", { initialView: VIEW.REVIEW });
	};
	
	const doDelete = (props) => {
		const ModalDeleteText = (props) => {
			return (
				<>
					<p>
						You are about to delete this program and all associated data. This is{" "}
						<em>
							<strong>irreversible!</strong>
						</em>
					</p>
					<p>Are you sure?</p>
					<Card title={props.item.name}>
						<p>Begin: {moment(props.item.dateStart).format("ddd, MMM Do Y")}</p>
						<p>End: {moment(props.item.dateEnd).format("ddd, MMM Do Y")}</p>
					</Card>
				</>
			);
		};

		confirm({
			title: "Delete this program?",
			icon: <ExclamationCircleOutlined />,
			content: <ModalDeleteText item={props} />,
			okText: <span style={{fontWeight: 'bold', color: '#D40214'}}>Delete</span>,
			okType: "danger",
			cancelText: "Cancel",
			onOk() {
				message.info(`Program '${props.name}' deleted!`);
				db.delete(props.id);
				setDeleted(true);
			},
		});
	};

	const getall = async () => {
		let ret = await db.readAll();
		ret.sort((x, y) => (x.dateStart < y.dateStart ? 1 : -1));
		setDeleted(false);
		setFileman([...ret]);
	};

	useEffect(() => {
		getall();
	}, []);

	useEffect(() => {
		getall();
	}, [deleted]);

	return fileman ? (
		<>
			<ProgramModal visible={creatorVisible} setVisible={setCreatorVisible} />
			<ConfigProvider renderEmpty={() => renderNoData({
				type: "Program",
				doCreateProgram,
			})}>
				<List
					header={
						<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
							<h1 style={{ margin: "0" }}>All Programs</h1>
							<Button type="primary" htmlType="submit"
								onClick={doCreateProgram} style={{backgroundColor: '#7AC637', border: '#239D14', fontWeight: 'bold'}}
							>
								<div style={{ display: 'flex', alignItems: 'center'}}>
									<FontAwesomeIcon icon={faPlus} style={{marginRight : '8px', fontSize: '1.25rem'}}/>
									Create
								</div>
							</Button>
						</div>
					}
					bordered
					// size="large"
					dataSource={fileman}
					renderItem={item => (
						<List.Item
							actions={[
								<Tooltip title="Edit Program">
									<FontAwesomeIcon icon={faEdit} onClick={() => doEditClick(item.id)} style={{fontSize: '1.25rem'}} />
								</Tooltip>,
								<Tooltip title="Export program to file">
									<FontAwesomeIcon icon={faSave} onClick={() => exportProgramToFile(item.id)} style={{fontSize: '1.25rem'}}/>
								</Tooltip>,
								<Tooltip title="Copy program to clipboard">
									<FontAwesomeIcon icon={faCopy} onClick={() => copyProgramToClipboard(item.id)} style={{fontSize: '1.25rem'}}/>
								</Tooltip>,
								<Tooltip title="Delete program">
									<FontAwesomeIcon onClick={() => doDelete(item)} icon={faTrash} color='#D40214' style={{fontSize: '1.25rem'}}/>
								</Tooltip>
							]}
						>
							<List.Item.Meta
								title={<h2>{item.name}</h2>}
								description={
									<p>{moment(item.dateStart).format("ddd, MMM Do Y")} - {moment(item.dateEnd).format("ddd, MMM Do Y")}
										<br />
										{item.nextSessionId} Sessions, {item.nextPresentationId} Presentations, {item.nextPresenterId} Presenters
									</p>
								}
							/>
						</List.Item>
					)}
				/>
			</ConfigProvider>
		</>
	) : (
			<Skeleton />
		);
};

export default File;