import React from 'react';

const Dashboard = () => {

  return (
    <div>
      <div>This is the Dashboard!</div>
    </div>
  );
};

export default Dashboard;
