import React from 'react';

const NotFoundPage = () => {
  return (
      <h2>
        ERROR 404: PAGE NOT FOUND
      </h2>
  );
};

export default NotFoundPage;