import React from "react";
import Fade from "react-reveal/Fade";

import {Button, message, Tooltip } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faEdit, faSave, faCopy } from "@fortawesome/free-regular-svg-icons"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { useHistory, Link } from "react-router-dom";

import './LandingPage.scss'; 
import { ProgramContext } from "../../contexts/Program";

const LandingPage = () => {
  const context = React.useContext(ProgramContext);

  const history = useHistory();

  async function handleInject(event) {
    event.preventDefault()
    await context.injectTestData()
    history.push("/manager")
  }
  
  return (
    <Fade bottom>
      <div className="help-text">
        <h1>
          Getting Started
        </h1>
        <p>
        </p>
        <h2>Step 1: Create a new program</h2>
        <ol>
          <li>Click '<FontAwesomeIcon icon={faCalendar} style={{marginLeft: '2px', marginRight: '5px'}}/> Programs' in the navigation menu.</li>
          <li>Click '<FontAwesomeIcon icon={faPlus} style={{marginLeft: '2px', marginRight: '5px'}}/> Create' to add a new program, or click 
          '<Tooltip title="Edit Program">
							<FontAwesomeIcon icon={faEdit} style={{margin: '2px'}} />
						</Tooltip>' to access an existing program.</li>
          <li>Add a <em>Program Name</em> and the <em>Program Start + End Dates</em> </li>
          <li>Click 'Continue'.</li>
        </ol>
        <h2>Step 2: Create sessions</h2>
        <ol>
          <li>You may add a session to each program day by clicking the '<FontAwesomeIcon icon={faPlus} style={{marginLeft: '2px', marginRight: '5px'}}/>' on the righthand program pane. The session date will be automatically selected.</li>
          <li>Add a <em>Session Name</em> and select the appropriate session <em>Date and Time</em></li>
          <li>Click 'Save Session'.</li>
          <li>You may add additional sessions, or proceed to creating the session's presentation.</li>
        </ol>
        <h2>Step 3: Create presentations</h2>
        <ol>
          <li>Click the '<FontAwesomeIcon icon={faPlus} style={{marginLeft: '5px', marginRight: '2px'}}/>'  button to add a new presentation.</li>
          <li>Add the <em>Presentation Name.</em></li>
          <li>Add <em>Presenters</em> to the presentation. Once added, you may reorder the presentation list display that will show on the program agenda.</li>
          <li>Select the <em>Credit Type</em> from the dropdown list.  Active credit types will automatically populate in this dropdown list selection.</li>
          <li>Select the available <em>Credit amount</em> and click 'Add credit'. Credits may be removed by clicking the 'X' associated with the <em>Current credits</em> list.</li>
          <li>Click 'Save Presentation' to complete.</li>
        </ol>
        <h2>Step 4: Export your program to EthosCE</h2>
        <ol>
          <li>From the Programs landing page, or Programs edit page, you may now export your program.</li>
          <li>Option 1: Click 
            <Tooltip title="Copy program to clipboard">
							  <FontAwesomeIcon icon={faCopy} style={{marginLeft: '5px', marginRight: '2px'}}/>
						</Tooltip> to copy the program contents.</li>
          <li>Option 2: Click 
            <Tooltip title="Export program to file">
                <FontAwesomeIcon icon={faSave} style={{marginLeft: '5px', marginRight: '2px'}}/>
						</Tooltip> to save the program to a text file.</li>
          <li>Visit the agenda tab in the EthosCE activity editing pane.</li>
          <li>Paste the agenda YAML contents into the agenda form field. Click save.</li>
        </ol>
        <h2>Want to try it out?</h2>
        <p>Click on the button below to add an example program to your program list.</p>
      </div>
      <Button type="primary" htmlType="submit"
								onClick={handleInject} style={{backgroundColor: '#7AC637', border: '#239D14', fontWeight: 'bold'}}
							>
								<div style={{ display: 'flex', alignItems: 'center'}}>
									<FontAwesomeIcon icon={faPlus} style={{marginRight : '8px', fontSize: '1.25rem'}}/>
									Add Example
								</div>
							</Button>
    </Fade>
  );
};

export default LandingPage;
