import YAML from "yaml";
import FileSaver from "file-saver";
import moment from "moment";

import { message } from "antd";

import db from "../../data/database";

async function exportProgramToFile(id) {
	/*
	  1. Create file blob from YAML object
	  2. Push to browser (prompt file save dialog)
	*/

	const [yamlProgram, dbProgram] = await getProgramAsYaml(id);

	const yamlFile = new Blob([yamlProgram], { type: "text/yaml;charset=utf-8" });

	FileSaver.saveAs(yamlFile, `${dbProgram.name}.yaml`);
}

async function copyProgramToClipboard(id) {
	//! clipboard API not supported by IE
	/*
	  1. Push YAML to browser clipboard object 
	*/

	const [yamlProgram] = await getProgramAsYaml(id);

	navigator.clipboard
		.writeText(yamlProgram)
		.then(() => {
			message.success("Program copied to clipboard!");
		})
		.catch(() => {
			message.error("Could not copy program to clipboard, try another browser?");
		});
}

// Helper func
async function getProgramAsYaml(id) {
	// Retrieve program and return as YAML object
	const dbProgram = await db.read(id);

	// Massage program object to required shape
	let send = { days: [] }

	for (const [i, day] of dbProgram.days.entries()) {
		let singleDay = {}
		singleDay.title = "QQQQ" + moment(day.date).format("MMMM Do") + "QQQQ"

		if (day.sessions.length > 0) {
			singleDay.sessions = []
			for (const [j, session] of day.sessions.entries()) {
				let singleSess = {}
				singleSess.title = "QQQQ" + session.name + "QQQQ"
				singleSess.presentations = []
				if (session.presentations.length > 0) {
					for (const [k, presentation] of session.presentations.entries()) {
						let creditList = {}
						Object.entries(presentation.credits).map((element) => {
							const [key, value] = element
							creditList[key] = Number(value)
						})

						const singlePres = {
							title: "QQQQ" + presentation.name+ "QQQQ",
							credits: creditList,
							speakers: "QQQQ" + presentation.presenters.join(", ")+ "QQQQ",
							start: "QQQQ" + moment(session.dateStart).format("HH:mm:00")+ "QQQQ",
							end: "QQQQ" + moment(session.dateEnd).format("HH:mm:00")+ "QQQQ",

						}
						singleSess.presentations.push(singlePres)
					}
				}
				singleDay.sessions.push(singleSess)
			}
		}
		send.days.push(singleDay)
	}

	// YAML.scalarOptions.str.defaultType = 'QUOTE_DOUBLE'
	// YAML.defaultOptions = { simpleKeys: true }
	// YAML.scalarOptions.str.defaultKeyType = 'PLAIN'

	const regex = /QQQQ/gi
	const output = (YAML.stringify(send).replace(regex, '"'));
	
	return [output, dbProgram];
}

export { exportProgramToFile, copyProgramToClipboard };