import React, { useState, useEffect, useRef } from "react";
import { ProgramContext } from "../../../contexts/Program";

import { Row, Col } from "antd";
import { Form, Input, Button, Select } from "antd";
const { Option } = Select;

function CreditInput(props) {
  const {
    parentForm,
    credits,
    setCredits,
    creditsList,
    setCreditsList,
  } = props;

  const creditInputElement = useRef(null);

  const context = React.useContext(ProgramContext);

  useEffect(() => {
    context.loadCreditTypes()
  }, [])

  useEffect(() => {
    // Clear credit fields & update credit list select state
    parentForm.setFieldsValue({
      creditType: "",
      creditAmount: 0,
      creditList: creditsList,
    });
  }, [creditsList]);

  const addCredit = () => {
    // HELPER FUNC
    const clearErrors = () => {
      parentForm.setFields([
        {
          name: "creditType",
          errors: [],
        },
        {
          name: "creditAmount",
          errors: [],
        },
      ]);
    };

    const creditType = parentForm.getFieldValue("creditType");
    const creditAmount = Number(parentForm.getFieldValue("creditAmount"));

    const newCredit = {};
    newCredit[creditType] = creditAmount;

    if (creditType && creditAmount) {
      clearErrors();

      setCredits({ ...credits, ...newCredit });

      setCreditsList([...creditsList, `${creditType}: ${creditAmount}`]);
    } else {
      parentForm.setFields([
        {
          name: "creditType",
          errors: ["Required to add a credit to presentation."],
        },
        {
          name: "creditAmount",
          errors: ["Required to add a credit to presentation."],
        },
      ]);
    }

    // Set focus back to input
    creditInputElement.current.focus();
  };

  const selectCredit = (credit) => {
    const splitCreds = credit.split(" ");

    const creditKey = splitCreds[0];
    const creditAmount = splitCreds[2];

    const newCredit = {};

    newCredit[creditKey] = creditAmount;

    setCredits({ ...credits, ...newCredit });

    setCreditsList([...creditsList, credit]);
  };

  const deselectCredit = (credit) => {
    const creditKey = credit.split(" ")[0];
    const modCredits = credits;

    delete modCredits[creditKey];

    setCredits(modCredits);

    setCreditsList(creditsList.filter((c) => c !== credit));
  };

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      addCredit()
    }
  }
  if (context.validCreditTypes.credit_types === undefined) {
    return (
      <div>
        ERROR LOADING VALID CREDIT TYPES, please launch the application from
        your Ethosce website to properly load credit types.
      </div>
    );
  }

  if (context.validCreditTypes.credit_types.length === 0) {
    return (
      <div>
        ERROR LOADING VALID CREDIT TYPES, please launch the application from
        your Ethosce website to properly load credit types.
      </div>
    );
  }
  return (
    <>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item
            label="Credit Type"
            labelCol={{ span: 24 }}
            name="creditType"
          >
            <Select
              showSearch
              placeholder="Select a option and change input text above"
              ref={creditInputElement}
            >
              {context.validCreditTypes.credit_labels.map((label, idx) => (
                <Option key={label} value={context.validCreditTypes.credit_types[idx]} label={label}>
                  <div>{label}</div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Credit Amount"
            labelCol={{ span: 24 }}
            name="creditAmount"
            onKeyPress={handleKeyPress}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="button" onClick={addCredit} tabIndex="-1" style={{backgroundColor: '#7AC637', border: 'green', fontWeight: 'bold'}}>
              Add Credit
            </Button>
          </Form.Item>
        </Col>
      </Row>

      {/* CREDIT LIST */}
      <Row>
        <Col span={24}>
          <Form.Item
            label="Current Credits"
            labelCol={{ span: 24 }}
            name="creditList"
          >
            <Select
              tabIndex="-1"
              mode="multiple"
              onSelect={selectCredit}
              onDeselect={deselectCredit}
              defaultValue={creditsList}
              placeholder="Credits will display here as they are added."
            >
              {creditsList.map((credit, idx) => {
                return (
                  <Option key={credit} value={credit}>
                    {credit}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default CreditInput;
