//Packages
import React, {useState} from "react";

// antd setup
import { Button, } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import "antd/dist/antd.css";

//Components

function renderNoData(data) {
	const { type, date, sessionId, handleAddSession, handleAddPresentation, doCreateProgram } = data

	const onClick = (e) => {
		switch (true) {
			case (type === "Program" && typeof doCreateProgram === "function"):
				doCreateProgram()
				break;
		
			case (type === "Session" && typeof handleAddSession === "function"):
				handleAddSession(date)
				break;
		
			case (type === "Presentation" && typeof handleAddPresentation === "function"):
				handleAddPresentation(sessionId)
				break;
		
			default:
				break;
		}

	}

	return (
		<div className="noData">
			<Button type="primary" shape="round" style={{backgroundColor: '#7AC637', border: '#239D14'}} icon={<FontAwesomeIcon icon={faPlus} style={{fontSize: '1.25em'}}/>} size="large" onClick={onClick}/>
			<p style={{fontWeight: 'bold'}}>Add New {type}</p>
		</div>
	)
}

export default renderNoData;
