//Packages
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faFile } from "@fortawesome/free-regular-svg-icons"
import "antd/dist/antd.css";
import "./Nav.scss";

const Nav = () => {
  const [current, setcurrent] = useState({ current: "home" });

  const handleClick = (e) => {
    setcurrent({ current: e.key });
  };

  return (
    <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" defaultSelectedKeys={["Home"]}>
      <Menu.Item key="home" icon={<FontAwesomeIcon icon={faFile} style={{fontSize: '1.25rem'}}/>}>
        <Link style={{fontSize: '1.25rem', fontWeight: 'bold'}} to="/"> Get Started</Link>
      </Menu.Item>
      <Menu.Item key="calendar" icon={<FontAwesomeIcon icon={faCalendar} style={{fontSize: '1.25rem'}}  />}>
        <Link style={{fontSize: '1.25rem', fontWeight: 'bold'}} to="/manager"> Programs</Link>
      </Menu.Item>
    </Menu>
  );
};

export default Nav;
