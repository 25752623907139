import React, { useEffect, useContext } from 'react';
import {useHistory, useLocation} from "react-router-dom"
import { ProgramContext } from "../../contexts/Program";

const SetCredits = () => {
    const context = useContext(ProgramContext);
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        context.parseUrl(location);
        history.push('/');
    }, []);
    return (
        <h2>Setting Credits...</h2>
    );
};

export default SetCredits;