import Dexie from "dexie";

if(process.env.NODE_ENV === 'test') {
   var indexedDB = require('fake-indexeddb')
}

var db;

if(process.env.NODE_ENV === 'test'){
    db = new Dexie("MyDatabase", { indexedDB: indexedDB });
} else {
    db = new Dexie("programs_db");
}

export default db;