import db from '../utilities'

db.version(1).stores({
  programs: "++id",
  creditTypes: "id",
});

db.start = () => {
  db.open()
    .then((x) => {
      conlog(">>> DB Opened: ", x.name);
    })
    .catch((err) => {
      console.error(">>> DB Open error: ", err.stack || err);
    });
};

db.read = (data) => {
  return db.programs
    .get(data)
    .then((x) => {
      if (x) {
        // x = JSON.parse(x.object)
        conlog(">>> DB Read:", data, x);
        return x;
      }
      return 0;
    })
    .catch((err) => {
      console.error(">>> DB Read error: ", err);
    });
};

db.readAll = async (data) => {
  return db.programs.where("id").above(0).toArray();
};

db.insert = (data) => {
  const dataString = JSON.parse(JSON.stringify(data));
  delete dataString.id;
  return db.programs
    .add(dataString)
    .then((x) => {
      conlog(">>> DB Added:", x, dataString);
      return x;
    })
    .catch((err) => {
      console.error(">>> DB Add error", dataString, err);
    });
};

db.delete = (id) => {
  return db.programs
    .delete(id)
    .then((ret) => {
      conlog(">>> DB ID Deleted (returns `undefined`):", ret);
    })
    .catch((err) => {
      console.error(">>> DB ID Delete error", id, err);
    });
};

db.update = (data) => {
  const dataString = JSON.parse(JSON.stringify(data));
  return db.programs
    .put(dataString)
    .then((x) => {
      conlog(">>> DB Updated:", x, dataString);
      return x;
    })
    .catch((err) => {
      console.error(">>> DB Update error", err, dataString);
      console.warn(">>> DB Update error", err.inner.message);
    });
};

db.updateCreditTypes = (data) => {
  let d = { id: 0, credit_labels: data.credit_labels, credit_types: data.credit_types };
  const dataString = JSON.parse(JSON.stringify(d));
  return db.creditTypes
    .put(dataString)
    .then((x) => {
      conlog(">>> creditTypes DB Updated:", x, dataString);
      return x;
    })
    .catch((err) => {
      console.error(">>> DB Update error", err, dataString);
      console.warn(">>> DB Update error", err.inner.message);
    });
};

db.getCreditTypes = () => {
  return db.creditTypes
    .get(0)
    .then((x) => {
      if (x) {
        // x = JSON.parse(x.object)
        return {credit_labels: x.credit_labels, credit_types: x.credit_types};
      }
      return [];
    })
    .catch((err) => {
      console.error(">>> DB Read error: ", err);
    });
};

export default db;

function conlog() {
  const show = false;
  if (show) {
    for (const arg in arguments) {
      console.dir(arguments[arg]);
    }
  }
}

module.export = { db };
